import { axios } from "../../../shared/lib/axios";
import { useQuery } from "@tanstack/react-query";
import _cookies from "../../../shared/utils/_cookies";

export const getTrackInfo = async ({ trackId }) => {
  const res = await axios.middleware("/aluno.asmx/dados_trilha", {
    params: {
      trilhaid: trackId,
      alunoid: _cookies.getItem("auth").user?.alunoid,
    },
  });
  return res[0].data[0];
};

export const useTrackInfo = ({ config, trackId }) => {
  return useQuery({
    ...config,
    queryKey: ["trackInfo", { trackId }],
    queryFn: () => getTrackInfo({ trackId }),
  });
};
