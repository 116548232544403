import {
  Avatar,
  Button,
  CircularProgress,
  List,
  ListItem,
  Typography,
  Box,
  useTheme,
  ListItemButton,
} from "@mui/material";
import { styled } from "@mui/material";
import {
  Check,
  PlayArrowRounded,
  LockOutlined,
  Update,
  Circle,
  Clear,
} from "@mui/icons-material";
import { useTrackCourses } from "../api/getTrackCourses";
import { useTrackInfo } from "../api/getTrackInfo";
import { getCourseStatus } from "../utils/courseStatus";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressTracker from "../../../ui/components/ProgressTracker";
import { getTimeUsed } from "../../../shared/utils/timeDif";

import { ClockStart, CertificateOutline, CursorDefault } from "mdi-material-ui";
import { useDispatch } from "react-redux";
import { setLastMenuPath } from "../../../shared/store/menu/menu";

function StepIcon({ sx, status }) {
  let Icon = Circle;
  let backgroundColor = "grey";
  let color = "300";
  switch (status) {
    case "completed":
      Icon = Check;
      backgroundColor = "success";
      color = "common";
      break;
    case "disabled":
      Icon = LockOutlined;
      backgroundColor = "grey";
      color = "common";
      break;
    case "active":
      Icon = PlayArrowRounded;
      backgroundColor = "info";
      color = "common";
      break;
    default:
      break;
  }
  const StyledIcon = styled(Icon)(({ theme, fontSize }) => ({
    backgroundColor:
      theme.palette[backgroundColor]?.light ||
      theme.palette[backgroundColor][300],

    fontSize: theme.spacing(5),
    padding: theme.spacing(1),
    color: theme.palette[color]?.white || theme.palette.grey[color],
    marginBottom: "10px",
    borderRadius: "50%",
    ...sx,
  }));
  return <StyledIcon />;
}

function InitCourseButton({ trackId, disciplinaId, label }) {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    navigate(`/registration/${disciplinaId}?trilhaid=${trackId}`, {
      state: { backgroundLocation: location },
      replace: true,
    });
  };

  return (
    <Button onClick={handleClick} variant="outlined" endIcon={<ClockStart />}>
      {label}
    </Button>
  );
}

function CertificateButton({ course }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleClick = (event) => {
    navigate(
      `/certificate/${course.matricula_conclusa}/${course.DISCIPLINAID}?from=${pathname}`
    );
  };
  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      endIcon={<CertificateOutline />}
    >
      Certificado
    </Button>
  );
}

function CourseProgress({ course }) {
  const proportionalTimePast = getTimeUsed(
    course?.DTLIBERACAO,
    course?.DTLIMITE
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        // width: "50px",
        div: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        },
      }}
    >
      {!!course.PORCENTAGEM_CONTEUDO && (
        <div>
          <ProgressTracker
            progress={course.PORCENTAGEM_CONTEUDO}
          ></ProgressTracker>
          <Typography sx={{ fontSize: "0.7rem", fontWeight: "600" }}>
            {course.PORCENTAGEM_CONTEUDO}%
          </Typography>
        </div>
      )}
      <div>
        <ProgressTracker
          progress={proportionalTimePast}
          variant="clock"
          sx={{ width: "24px" }}
        />
        <Typography sx={{ fontSize: "0.7rem", fontWeight: "600" }}>
          {proportionalTimePast}% do tempo <br /> utilizado
        </Typography>
      </div>
    </Box>
  );
}

function Actions({ course, trackId, status }) {
  switch (status) {
    case "completed":
      return (
        <>
          {course.EMITIR_CERTIFICADO === "S" && (
            <CertificateButton course={course} />
          )}
          {course.acessoilimitado === "S" && <> acesso ilimitado</>}
        </>
      );
    case "disabled":
      return <></>;
    case "default":
      return (
        <InitCourseButton
          trackId={trackId}
          disciplinaId={course.DISCIPLINAID}
          label="Iniciar agora"
        />
      );
    case "active":
      if (course?.PORCENTAGEM_CONTEUDO == 100) {
        return (
          <Typography sx={{ fontSize: "0.7rem", fontWeight: "600" }}>
            {course.situacao}{" "}
          </Typography>
        );
      }
      return <CourseProgress course={course} />;
    default:
      return <></>;
  }
}

export default function TrackCourses({ trackId }) {
  const theme = useTheme();
  const { data, isLoading, isError } = useTrackCourses({ trackId });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const trackInfo = useTrackInfo({ trackId });
  if (isLoading || isError || trackInfo.isLoading || trackInfo.isError) {
    return <CircularProgress />;
  }

  return (
    <List>
      {data.map((course, index) => {
        const status = getCourseStatus(course, trackInfo.data);
        const canOpenCourse =
          (course.matricula_conclusa && course.acessoilimitado === "S") ||
          (course.matricula_ativa && course.LIBERADO === "S");
        const handleClick = () => {
          if (canOpenCourse) {
            dispatch(setLastMenuPath(location.pathname));
            navigate(`/curso/${trackId}/${course.DISCIPLINAID}`);
          }
        };

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <StepIcon status={status} />
            <ListItem
              sx={{
                marginLeft: 1,
                marginBottom: 2,
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: 1,
                [theme.breakpoints.down("md")]: {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
              key={`curso ${course.DISCIPLINAID} ${index}`}
            >
              <>
                <Avatar src={course.foto} />
                <ListItemButton onClick={handleClick} disabled={!canOpenCourse}>
                  <Typography> {course.nome}</Typography>
                </ListItemButton>
              </>
              <Actions trackId={trackId} course={course} status={status} />
            </ListItem>
          </div>
        );
      })}
    </List>
  );
}
