import {
  useTheme,
  ImageListItemBar,
  ImageListItem,
  styled,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, Close } from "@mui/icons-material";
import { Backdrop, Box, IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import { useTimedCallback } from "../../../shared/hooks/useTimedCallback";
import ImageWithBackdrop from "../ImageWithBackdrop";
import Indicator from "./Indicator";

const GalleryBox = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
  overflow: "hidden",
  // position: "relative",
}));

const GalleryMiniatureTitleBar = styled(ImageListItemBar)(({ theme }) => ({
  fontSize: "1rem",
  background:
    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
}));
const GalleryMiniatureImageWrapper = styled(ImageListItem, {
  shouldForwardProp: (prop) => prop !== "displacement",
})(({ theme, displacement }) => ({
  position: "absolute",
  transform: `translateX(${displacement * 100}%)`,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeInOut,
  }),
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  objectFit: "contain",
}));

const GalleryMiniatureImage = styled("img")(({ theme }) => ({
  // aspectRatio: "9 / 16",
  // height: "100%",
  // width: "auto",
  maxHeight: "100%",
  maxWidth: "100%",
  display: "block",
  objectFit: "contain",

  objectFit: "cover",
}));

const GalleryMiniatureBottomText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey["600"],
  mixBlendMode: "difference",
  position: "absolute",
  bottom: 0,
}));

function ImageGallery({ imgSrcs, deactivateBackdrop = false }) {
  const [state, setState] = useState({
    currentImageIndex: 0,
    isFullScreenOpen: false,
    rotationPaused: false,
    cyclesSincePause: 0,
    src: ``,
  });
  const stateRef = useRef();
  stateRef.current = state;

  const handlePauseRotation = () => {
    setState((prev) => ({
      ...prev,
      rotationPaused: true,
      cyclesSincePause: 0,
    }));
  };

  const handleResumeRotation = () => {
    setState((prev) => ({
      ...prev,
      rotationPaused: false,
      cyclesSincePause: 0,
    }));
  };

  const handlePreviousImg = () => {
    setState((prev) => {
      return {
        ...prev,
        currentImageIndex:
          prev.currentImageIndex === 0
            ? imgSrcs.length - 1
            : prev.currentImageIndex - 1,
      };
    });
  };

  const handlePreviousButtonClick = () => {
    handlePauseRotation();
    handlePreviousImg();
  };

  const handleNextImg = () => {
    setState((prev) => {
      return {
        ...prev,
        currentImageIndex:
          prev.currentImageIndex === imgSrcs.length - 1
            ? 0
            : prev.currentImageIndex + 1,
      };
    });
  };

  const handleNextButtonClick = () => {
    handlePauseRotation();
    handleNextImg();
  };
  const handleOpenFullScreen = () => {
    handlePauseRotation();
    setState((prev) => {
      return { ...prev, isFullScreenOpen: true };
    });
  };

  const handleTimePassed = useCallback(
    (arg) => {
      if (!stateRef.current.rotationPaused) {
        handleNextImg();
      } else {
        setState((prev) => ({
          ...prev,
          cyclesSincePause: prev.cyclesSincePause + 1,
        }));
        if (
          stateRef.current.cyclesSincePause > 2 &&
          !stateRef.current.isFullScreenOpen
        ) {
          handleResumeRotation();
        }
      }
    },
    [state]
  );

  useTimedCallback(handleTimePassed, 3500);

  const handleCloseFullScreen = () => {
    if (state.isFullScreenOpen) {
      setState((prev) => {
        // return { ...prev };
        return { ...prev, isFullScreenOpen: false };
      });
    }
  };

  const handleClickIndicator = (index) => {
    setState((prev) => ({
      ...prev,
      currentImageIndex: index,
    }));
    handlePauseRotation();
  };

  const theme = useTheme();

  return (
    <GalleryBox id="gallery_box">
      {imgSrcs.map((img, index) => {
        return (
          <GalleryMiniatureImageWrapper
            displacement={index - state.currentImageIndex}
            id="gallery_miniature_image_wrapper"
            onClick={handleOpenFullScreen}
          >
            <ImageWithBackdrop
              deactivateBackdrop={deactivateBackdrop}
              id="gallery_miniature_image"
              url={img}
              loading="lazy"
            />
          </GalleryMiniatureImageWrapper>
        );
      })}
      <GalleryMiniatureTitleBar
        id="gallery_miniature_title_bar"
        title={`${state?.currentImageIndex + 1} de  ${imgSrcs.length} imagens`}
        position="top"
        actionPosition="left"
      />
      <IconButton onClick={handlePreviousButtonClick}>
        <ArrowBackIos />
      </IconButton>
      <IconButton onClick={handleNextButtonClick}>
        <ArrowForwardIos />
      </IconButton>
      <Indicator
        onClick={handleClickIndicator}
        currentIndex={state?.currentImageIndex}
        length={imgSrcs?.length}
      />
      <Backdrop
        open={state.isFullScreenOpen}
        sx={{ zIndex: 1, backgroundColor: "rgba(0, 0, 0, 0.9)" }}
      >
        {state.isFullScreenOpen && (
          <ClickAwayListener
            onClickAway={() => {
              handleCloseFullScreen();
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "100%",
                columnGap: "12px",
              }}
            >
              <IconButton
                //   sx={{ backgroundColor: "white" }}
                sx={{
                  backgroundColor: "white",
                  height: "fit-content",
                  ...(state.currentImageIndex == 0
                    ? { visibility: "hidden" }
                    : {}),
                }}
                onClick={handlePreviousImg}
                disabled={state.currentImageIndex == 0}
              >
                <ArrowBackIos />
              </IconButton>
              <Box
                sx={{
                  // maxWidth: "80vw",

                  maxHeight: "80vh",
                  width: "auto",
                  display: "flex",
                  alignItems: "center",
                  img: {
                    background: `https://static.vecteezy.com/system/resources/thumbnails/008/174/698/small/animation-loading-circle-icon-loading-gif-loading-screen-gif-loading-spinner-gif-loading-animation-loading-on-black-background-free-video.jpg`,
                    // height: "auto",
                  },
                }}
              >
                <img src={imgSrcs[state.currentImageIndex]} loading="lazy" />
              </Box>

              <IconButton
                sx={{
                  backgroundColor: "white",
                  height: "fit-content",
                  ...(state.currentImageIndex + 1 == imgSrcs.length
                    ? { visibility: "hidden" }
                    : {}),
                }}
                onClick={handleNextImg}
                disabled={state.currentImageIndex + 1 == imgSrcs.length}
              >
                <ArrowForwardIos />
              </IconButton>
              <IconButton
                onClick={handleCloseFullScreen}
                sx={{
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                  color: "white",
                  backgroundColor: "red",
                }}
              >
                <Close />
              </IconButton>
            </Box>
          </ClickAwayListener>
        )}
      </Backdrop>
    </GalleryBox>
  );
}

export default ImageGallery;
