import { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchBirthdayList } from "../../../../../shared/store/dashboards";

import {
  Box,
  CardContent,
  List,
  ListItem,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Avatar,
  Stack,
} from "@mui/material";
import SearchBar from "../../../../components/SearchBarv2";
import MessageDialog from "./MessageDialog";
import EmptyListLabel from "../../../../components/EmptyListLabel";
import { baseUrl } from "../../../../../shared/config";
import ImageWithBackdrop from "../../../../components/ImageWithBackdrop";
import moment from "moment";
import { useBirthdayCalendar } from "../../../../../features/misc/api/getBirthdayCalendar";

const BirthdayModule = ({
  token,
  birthdayList,
  fetchBirthdayList,
  ...rest
}) => {
  const [filter, setFilter] = useState("");
  const [period, setPeriod] = useState("month");
  const birthdayCalendarQuery = useBirthdayCalendar({
    params: { filter, period },
  });
  const [addressee, setAddressee] = useState();
  const handleRadioChange = (event) => {
    setPeriod(event.target.value);
  };
  const handleSearch = (term) => {
    setFilter(term);
    return;
  };

  const { imagem } = rest;
  moment.locale("pt-br");
  moment.updateLocale("pt-br", {
    longDateFormat: {
      LLL: "DD / MMMM",
    },
  });

  return (
    <Grid
      sx={{ backgroundColor: "white", height: "550px", borderRadius: ".5rem" }}
      item
      sm={12}
    >
      <CardContent sx={{ height: "100%" }}>
        <Stack sx={{ height: "100%" }} spacing={1}>
          {!imagem ? (
            <header className="bg-slate-100 rounded-lg p-2 text-primary flex justify-center">
              <Typography
                sx={{
                  fontSize: "0.8em",
                  "@media screen and (min-width: 900px)": {
                    fontSize: "1em",
                  },
                }}
                variant="subtitle1"
                component="div"
              >
                <Box component="span" fontWeight="fontWeightBold">
                  Aniversariantes do Mês
                </Box>
              </Typography>
            </header>
          ) : (
            <Box sx={{ dislay: "flex" }}>
              <Box
                sx={{
                  aspectRatio: "72 / 7",
                  width: "100%",
                  height: "100",
                }}
              >
                ,
                <ImageWithBackdrop url={`${baseUrl + imagem}`} />
              </Box>
            </Box>
          )}
          <RadioGroup
            defaultValue={"Mês"}
            value={period}
            onChange={handleRadioChange}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel value="today" control={<Radio />} label="Hoje" />
            <FormControlLabel
              value="month"
              control={<Radio />}
              label="Esse mês"
            />
          </RadioGroup>

          <SearchBar onClickSearch={handleSearch} />
          <Box
            sx={{
              borderRadius: "4px",
              border: "1px solid var(--secondary_color_light)",
              display: "flex",
              bottom: "0",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {birthdayCalendarQuery.data?.length > 0 ? (
              <>
                <List sx={{ width: "100%" }}>
                  {birthdayCalendarQuery.data.map((aluno, index) => {
                    return (
                      <ListItem
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Avatar
                          src={`${baseUrl}/membros/fotos/${aluno?.foto.replace(
                            "~",
                            ""
                          )}`}
                        />
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            ml: 1,
                          }}
                        >
                          <Typography sx={{ width: "25%" }} flexWrap="nowrap">
                            {aluno?.nome}
                          </Typography>
                          <Typography>
                            {moment(new Date(aluno?.dtnasc))
                              .format("LLL")
                              .replace("/", "de")}
                          </Typography>
                          <Button
                            onClick={() => {
                              setAddressee(aluno);
                            }}
                          >
                            {" "}
                            Enviar mensagem
                          </Button>
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : (
              <EmptyListLabel
                text={
                  period === "Dia"
                    ? "Sem aniversariantes hoje"
                    : "Sem aniversariantes este mês"
                }
              />
            )}
          </Box>
        </Stack>
      </CardContent>
      {addressee && (
        <MessageDialog
          addressee={addressee}
          onClose={() => {
            setAddressee();
          }}
        ></MessageDialog>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    birthdayList: state.dashboard.birthdayList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchBirthdayList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BirthdayModule);
