import { useTrackInfo } from "../api/getTrackInfo";
import { CircularProgress, Box, Typography, useTheme } from "@mui/material";
import CardItem from "../../../ui/components/List/CardItem";
import { imagesUrl } from "../../../shared/config";
import moment from "moment";
import { Description, EventNote, Timer } from "@mui/icons-material";
import ProgressTracker from "../../../ui/components/ProgressTracker";
import { getTimeUsed } from "../../../shared/utils/timeDif";
import { useTenantParameters } from "../../misc/api/getTenantParameters";

function TrackCharacteristics({ label, subLabel, icon }) {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {icon}
      <div>
        <Typography
          sx={{
            fontSize: "0.6rem",
            [theme.breakpoints.up("md")]: {
              fontSize: "0.9rem",
            },
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.6rem",
            [theme.breakpoints.up("md")]: {
              fontSize: "0.9rem",
            },
          }}
          fontWeight={600}
        >
          {subLabel}
        </Typography>
      </div>
    </Box>
  );
}

function TrackStats({ variant, progress, label, subLabel, icon }) {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <ProgressTracker
        sx={{
          width: "1.5rem",
          height: "1.5rem",

          [theme.breakpoints.up("md")]: {
            width: "3rem",
            height: "3rem",
          },
          // [largeDisplay]: {
          //   width: "6rem",
          // },
        }}
        variant={variant}
        progress={progress}
      />

      <Typography
        fontWeight={"600"}
        sx={{
          fontSize: "0.6rem",
          color: theme.palette.grey[600],
          [theme.breakpoints.up("md")]: {
            fontSize: "0.9rem",
          },
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

export default function TrackInfo({ trackId }) {
  const { data, isLoading, isError } = useTrackInfo({ trackId });
  const tenantParams = useTenantParameters();
  if (isLoading || isError) {
    return <CircularProgress />;
  }

  const timeUsed = getTimeUsed(data.DATA_INICIO, data.DATA_LIMITE);
  const avatarSrc =
    data?.foto?.search(/http/) > -1 ? data?.foto : `${imagesUrl}/${data?.foto}`;
  return (
    <>
      <CardItem
        ActionAreaProps={{ disabled: true }}
        imageUrl={avatarSrc}
        label={`${data.NOME}`}
        footer={
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "space-between", md: "space-around" },
                // backgroundColor: "red",
              }}
            >
              <TrackCharacteristics
                label={``}
                subLabel={`${tenantParams.data?.NOMENCLATURA_DISCIPLINA}(s) ${data.qtd_disciplinas} `}
                icon={<Description color="primary" />}
              />
              <TrackCharacteristics
                label={"Prazo de conclusão:"}
                subLabel={`${data.DURACAO_DIAS} dias`}
                icon={<Timer color="primary" />}
              />
              <TrackCharacteristics
                label={"Início:"}
                subLabel={`${moment(data.DATA_INICIO).format("DD/MM/YYYY")}`}
                icon={<EventNote color="primary" />}
              />
              <TrackCharacteristics
                label={"Término:"}
                subLabel={`${moment(data.DATA_LIMITE).format("DD/MM/YYYY")}`}
                icon={<EventNote color="primary" />}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                paddingTop: 1,
                // backgroundColor: "red",
              }}
            >
              <TrackStats
                variant={"arc"}
                progress={
                  (100 * data.qtd_disciplinas_conclusas) / data.qtd_disciplinas
                }
                label={`${data.qtd_disciplinas_conclusas} / ${data.qtd_disciplinas} ${tenantParams.data?.NOMENCLATURA_CURSO} finalizados`}
                icon={"icon"}
              />
              <TrackStats
                variant={"clock"}
                progress={timeUsed}
                label={`${timeUsed}% do tempo utilizado`}
                icon={"icon"}
              />
            </Box>
          </Box>
        }
      ></CardItem>
    </>
  );
}
