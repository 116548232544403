import { Container, Grid } from "@mui/material";
import CreateSocialMediaPost from "./CreateSocialMediaPost";
import UserTitle from "./UserTitle";
import UserThemedPaper from "./UserThemedPaper";

import SocialMediaPostCompact from "./SocialMediaPostCompact";

import UserDesciption from "./UserDescription";
import UserGameficationAwards from "./UserGameficationAwards";

export default function MyProfile() {
  return (
    <Container sx={{ p: 2, overflow: "auto", height: "calc(100dvh - 64px)" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UserDesciption />
        </Grid>
        <Grid item xs={12}>
          <UserGameficationAwards />
        </Grid>
        <Grid item xs={12}>
          <UserThemedPaper>
            <UserTitle />
            <CreateSocialMediaPost />
          </UserThemedPaper>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            height: "650px",
          }}
        >
          <SocialMediaPostCompact />
        </Grid>
      </Grid>
    </Container>
  );
}
