import { styled } from "@mui/material";

import Box from "@mui/material/Box";

const BarWrapper = styled("div")(({ theme, color }) => ({
  width: "inherit",
  backgroundColor: theme.palette.grey[400],
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  minWidth: "40px",
  flexBasis: "40px",
  height: "8px",
  overflow: "hidden",
  [theme.breakpoints.up()]: {
    height: "12px",
  },
}));

const InnerBar = styled("div", {
  shouldForwardProps: (prop) => prop !== "progress" || prop !== "color",
})(({ theme, progress, color }) => ({
  position: "absolute",
  transform: `translateX(${progress}%)`,
  width: `100%`,
  height: "100%",
  backgroundColor: theme.palette[color]?.main || theme.palette?.[color] || theme.palette.success?.main,
  transition: "transform 2s linear",
}));

const fillBar = (currentProgress) => {
  const initialPos = -100;
  return initialPos + Number(currentProgress);
};

function ProgressBar({ sx, progress, color = "success", ...props }) {
  return (
    <Box sx={sx} {...props}>
      <BarWrapper>
        <InnerBar color={color} progress={fillBar(progress || 0)} />
      </BarWrapper>
    </Box>
  );
}

export default ProgressBar;
