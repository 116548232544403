import {
  Badge,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import ImageWithBackdrop from "../ImageWithBackdrop";

export default function CardItem({
  label = null,
  subLabel = null,
  additionalContent = null,
  imageUrl = null,
  footer = null,
  BadgeProps = {},
  ActionAreaProps = {},
  LabelProps = {},
  SubLabelProps = {},
  CardProps = {},
  CardContentProps = {},
  CardMediaProps = {},
}) {
  return (
    <Card
      {...CardProps}
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      {!!imageUrl && (
        <CardMedia
          sx={{
            aspectRatio: "16 / 9",
            height: { sm: "100%", xs: "auto" },
            width: { xs: "100%", sm: "auto" },
          }}
          {...CardMediaProps}
        >
          <ImageWithBackdrop url={imageUrl} />
        </CardMedia>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {(!!label || !!subLabel) && (
          <CardActionArea
            sx={{ flex: "3", height: "100%", overflow: "hidden" }}
            {...ActionAreaProps}
          >
            <CardContent
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              {...CardContentProps}
            >
              <Typography
                component="div"
                variant="h5"
                {...LabelProps}
                sx={{ fontWeight: "500" }}
              >
                {label}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                {...SubLabelProps}
              >
                {subLabel}
              </Typography>
              <Box sx={{ height: "100%" }}>{additionalContent}</Box>
            </CardContent>
          </CardActionArea>
        )}
        {footer && <Box sx={{ flex: 1 }}>{footer}</Box>}
      </Box>
    </Card>
  );
}
