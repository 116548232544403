import { useLocation, useParams, useSearchParams } from "react-router-dom";
import TrackInfo from "../../tracks/components/TrackInfo";
import TrackCourses from "../../tracks/components/TrackCourses";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import NavigationPage from "../../../ui/templates/NavigationPage";
import { useSelector, useStore } from "react-redux";

export default function CertificationTrack() {
  const { trackId } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const menu = useSelector((state) => state.menu);
    const theme = useTheme();
  const isMobileDisplay = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <NavigationPage
      rootRoute={{ label: menu.title, route: "/certificacoes" }}
      themeRoute={{ label: searchParams.get("title") || "Trilha" }}
    >
      <Container maxWidth={false} disableGutters={isMobileDisplay}>
        {/* <Track trackId={trackId} />
      <Track */}
        <TrackInfo trackId={trackId} />
        <TrackCourses trackId={trackId} />
      </Container>
    </NavigationPage>
  );
}
