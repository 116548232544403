import { Box, IconButton, Stack, styled } from "@mui/material";
import Form from "../../../ui/components/Form";
import RichTextField from "../../../ui/components/Form/RichTextField";
import LinkIcon from "@mui/icons-material/Link";
import * as yup from "yup";
import {
  InsertPhoto,
  PictureAsPdf,
  VideoCameraBack,
} from "@mui/icons-material";

const UploaderButtons = styled(IconButton)(({ theme }) => ({
  fontSize: "50px",
}));

const schema = yup.object().shape({
  textContent: yup.string().required("Campo obrigatório"),
});

const defaultValues = {
  textContent: "",
};

export default function CreateSocialMediaPost() {
  const handleSubmit = (values) => {};
  return (
    <>
      <Form
        schema={schema}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      >
        {(formMethods) => {
          return (
            <Stack>
              <RichTextField
                formMethods={formMethods}
                fieldName={"textContent"}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <UploaderButtons fontSize="large">
                  <VideoCameraBack />
                </UploaderButtons>
                <UploaderButtons>
                  <InsertPhoto />
                </UploaderButtons>
                <UploaderButtons>
                  <LinkIcon />
                </UploaderButtons>
                <UploaderButtons>
                  <PictureAsPdf />
                </UploaderButtons>
              </Box>
            </Stack>
          );
        }}
      </Form>
    </>
  );
}
