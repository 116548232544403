import { Avatar, Box, Button, Typography } from "@mui/material";
import _cookies from "../../../shared/utils/_cookies";
import UserThemedPaper from "./UserThemedPaper";
import { Description, Send } from "@mui/icons-material";
import { baseUrl } from "../../../shared/config";

export default function UserDesciption() {
  console.log(_cookies.getItem("auth"));
  return (
    <UserThemedPaper>
      <Box sx={{ display: "flex" }}>
        <span>
          <Typography variant={"h4"}>
            {_cookies.getItem("auth").user?.nome}
          </Typography>
          <Typography>
            Apresentação: uma pequena frase escrita pelo próprio aluno
          </Typography>
        </span>

        <Avatar
          sx={{ ml: "auto", height: "100px", width: "100px" }}
          src={`${baseUrl}/membros/fotos/${_cookies.getItem("auth").user.foto}`}
        />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", paddingTop: 1 }}
      >
        <Button sx={{ color: "grey" }} startIcon={<Description />}>
          visualizar <br /> currículo
        </Button>
        <Button sx={{ color: "grey", textAlign: "right" }} endIcon={<Send />}>
          enviar <br /> mensagem
        </Button>
      </Box>
    </UserThemedPaper>
  );
}
