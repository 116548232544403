import {
  Avatar,
  Container,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CreateSocialMediaPost from "./CreateSocialMediaPost";
import UserTitle from "./UserTitle";
import UserThemedPaper from "./UserThemedPaper";
import BirthdayModule from "../../../ui/pages/Dashboard/modules/BirthdayModule/BirthdayModule";
import { useBirthdayCalendar } from "../../misc/api/getBirthdayCalendar";
import { baseUrl } from "../../../shared/config";
import SocialMediaPostCompact from "./SocialMediaPostCompact";
import NavigationPage from "../../../ui/templates/NavigationPage";

export default function SocialMediaFeedPage() {
  const birthdayCalendarQuery = useBirthdayCalendar({
    params: { period: "month", filter: "" },
  });
  return (
    <Container sx={{ p: 2, overflow: "auto", height: "calc(100dvh - 64px)" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UserThemedPaper>
            <UserTitle />
            <CreateSocialMediaPost />
          </UserThemedPaper>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{
            ".MuiGrid-item": {
              height: "250px",

              ".MuiPaper-root": { height: "100%", overflow: "auto" },
            },
          }}
          spacing={2}
        >
          <Grid item xs={6}>
            <UserThemedPaper>
              <Stack>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Aniversariantes do Dia
                </Typography>
                {birthdayCalendarQuery.data?.map((item) => {
                  return (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          src={`${baseUrl}/membros/fotos/${item?.foto}`}
                        />
                      </ListItemAvatar>
                      <ListItemText>{item?.nome}</ListItemText>
                    </ListItem>
                  );
                })}
              </Stack>
            </UserThemedPaper>
          </Grid>
          <Grid item xs={6}>
            <UserThemedPaper>
              <Stack>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Espaço do gestor
                </Typography>
              </Stack>
            </UserThemedPaper>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: "650px",
          }}
        >
          <SocialMediaPostCompact />
        </Grid>
      </Grid>
    </Container>
  );
}
