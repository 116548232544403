import { alpha, styled, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/styles";
import { useRef } from "react";

const SearchBarWrapper = styled("div")(({ theme, disabled }) => ({
  display: "flex",
  border: `solid 1px ${
    disabled ? theme.palette.grey[400] : "var(--secondary_color_light)"
  }`,
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  width: "100%",
  borderRadius: "5px",
  alignItems: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Button = styled("button")(({ theme }) => ({
  height: "100%",
}));

export const testItem = (item, testString) => {
  const regex = new RegExp("(" + testString + ")", "i");
  return regex.test(item);
};

const SearchBar = ({
  value,
  onChange = () => {},
  onClickSearch = () => {},
  disabled = false,
}) => {
  const theme = useTheme();
  const ref = useRef();
  const handleInputChange = (event) => onChange(event.target.value);
  const handleSubmit = () => {
    onClickSearch(ref.current.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(ref.current.value);
    }
  };
  return (
    <SearchBarWrapper disabled={disabled}>
      <Button disabled={disabled} onClick={handleSubmit}>
        <SearchIconWrapper>
          <SearchIcon
            sx={{
              color: `${
                disabled
                  ? theme.palette.grey[400]
                  : "var(--secondary_color_light)"
              }`,
            }}
          />
        </SearchIconWrapper>
      </Button>
      <InputBase
        inputRef={ref}
        disabled={disabled}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Pesquisar"
        inputProps={{ "aria-label": "search" }}
      />
    </SearchBarWrapper>
  );
};

export default SearchBar;
