import {
  Dialog,
  Stack,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import { Close, Send } from "@mui/icons-material";
import * as yup from "yup";
import Form from "../../../../components/Form";
import { useSendBirthdayMessage } from "../../../../../features/misc/api/sendBirthDayMessage";
import RichTextField from "../../../../components/Form/RichTextField";
import { useSendEmail } from "../../../../../features/email/api/sendEmail";

const schema = yup.object().shape({
  message: yup.string().required("Esse campo é obrigatório"),
});

const defaultValues = {
  message: "",
};

export default function MessageDialog({
  addressee,

  onClose,
}) {
  const sendEmailMutation = useSendEmail();
  const handleSubmit = (values) => {
    sendEmailMutation.mutate(
      {
        message: values.message,
        recipient: { studentId: addressee.alunoid, name: addressee.nome },
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };
  return (
    <Dialog open fullWidth maxWidth="md">
      <IconButton
        sx={{ position: "absolute", top: 3, right: 3 }}
        onClick={() => {
          onClose();
        }}
      >
        <Close></Close>
      </IconButton>
      <DialogTitle>Mandar mensagem</DialogTitle>
      <DialogContent>
        <Stack>
          <Typography variant="caption" color="grey">
            Para: {addressee.nome}
          </Typography>
          <Typography variant="caption" color="grey">
            Assunto: Feliz Aniversário
          </Typography>

          <Form
            schema={schema}
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
          >
            {(formMethods) => {
              return (
                <>
                  <RichTextField
                    formMethods={formMethods}
                    fieldName={"message"}
                  />
                  <Button type="submit" endIcon={<Send />}>
                    Enviar
                  </Button>
                </>
              );
            }}
          </Form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
