import { Box, alpha, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
  borderRadius: "999px",
  position: "absolute",
  overflow: "hidden",
  width: "45px",
  height: "20px",
  bottom: "5px",
  left: "50%",
  right: "50%",
  display: "flex",
  backgroundColor: alpha(theme.palette.grey[700], 0.7),
  alignItems: "center",
}));

const Dot = styled("span", {
  shouldForwardProp: (prop) =>
    prop !== "displacement" || prop !== "grow" || prop !== "shrink",
})(({ theme, displacement, grow, shrink }) => ({
  position: "absolute",
  transform: `translateX(calc(${displacement * 100}% + 19.5px + ${
    displacement * 7.5
  }px)) scale(${grow ? "1.5" : shrink ? "0.7" : "1"})`,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeInOut,
  }),
  height: "5px",
  cursor: "pointer",
  width: "5px",
  borderRadius: "50%",
  backgroundColor: "white",
  display: "inline-block",
}));

export default function Indicator({
  currentIndex,
  length,
  onClick = () => {},
}) {
  const handleClick = (index) => (event) => {
    onClick(index);
  };
  return (
    <Wrapper>
      {Array.from({ length }, (v, k) => k + 1).map((_, index) => (
        <>
          <Dot
            onClick={handleClick(index)}
            displacement={index - currentIndex}
            grow={currentIndex == index}
            shrink={index == 0 || index == length - 1}
          />
        </>
      ))}
    </Wrapper>
  );
}
