import { styled } from "@mui/material";

import Box from '@mui/material/Box';

import CircularProgress from "../components/CircularProgress";

import { calcCircularProgressRotation } from "../../common/utils";

const ClockPointer = styled("div")(({ theme, progress, color }) => ({
  position: "absolute",
  top: "10%",
  height: "40%",
  width: `${theme.spacing(0.4)}`,
  backgroundColor: theme.palette[color]?.main || theme.palette.grey[600],
  borderRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: "0",
  borderBottomRightRadius: "0",
  transform: `rotate(${calcCircularProgressRotation(progress || 0)}deg)`,
  transition: "transform 1s ease-in-out",
  transformOrigin: "bottom center",
}));

const ClockBorder = styled("div")(({ theme, color }) => ({
  borderWidth: theme.spacing(0.4),
  borderColor: theme.palette[color]?.main || theme.palette.grey[600],
  borderStyle: "solid",
  borderRadius: "50%",
  width: "100%",
  height: "100%",
  position: "relative",
  display: "flex",
  justifyContent: "center",
}));

function ProgressClock({ className, sx, maxWidth, color, progress, ...props }) {
  return (
    <Box className={className} sx={{ maxWidth: maxWidth, ...sx }}>
      <ClockBorder color={color} {...props}>
        <CircularProgress color={color} progress={progress} />
        <ClockPointer color={color} />
        <ClockPointer color={color} progress={progress} />
      </ClockBorder>
    </Box>
  );
}

export default ProgressClock;
