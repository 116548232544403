import { Avatar, Box, styled, Typography } from "@mui/material";
import _cookies from "../../../shared/utils/_cookies";
import { baseUrl } from "../../../shared/config";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export default function UserTitle() {
  return (
    <Wrapper>
      <Avatar
        src={`${baseUrl}/membros/fotos/${_cookies.getItem("auth").user.foto}`}
      />
      <Typography variant="h6">{_cookies.getItem("auth").user.nome}</Typography>
    </Wrapper>
  );
}
