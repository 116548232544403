import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";

const rotateProgressBar = (rotation) => {
  const initialPos = -45;
  const filledBar = 180;
  if (!rotation) return initialPos;
  return (filledBar * rotation) / 100 + initialPos;
};

const ProgressArcWrapper = styled("div")(({ theme }) => ({
  aspectRatio: "2/1",
  overflow: "hidden",
  position: "relative",
}));

const ProgressArcTrack = styled("div")(({ theme, thickness }) => ({
  borderWidth: theme.spacing(thickness || 2),
  borderColor: theme.palette.grey[200],
  borderStyle: "solid",
  borderRadius: "50%",
  position: "absolute",
  width: "100%",
  aspectRatio: "1/1",
  zIndex: 1,
}));

const ProgressArcFill = styled("div", {
  shouldForwardProp: (prop) => prop !== "progress",
})(({ theme, rotation, thickness }) => ({
  position: "relative",
  zIndex: 2,
  borderWidth: theme.spacing(thickness || 2),
  borderColor: theme.palette.secondary.dark,
  borderStyle: "solid",
  borderRadius: "50%",
  transform: `rotate(${rotation}deg)`,
  borderRightColor: "transparent",
  borderTopColor: "transparent",
  width: "100%",
  height: "200%",
  transition: "transform 2s linear",
}));

function ProgressArc({ className, sx, progress, thickness, ...props }) {
  return (
    <Box sx={sx} className={className} {...props}>
      <ProgressArcWrapper>
        <ProgressArcTrack thickness={Number(thickness)} />
        <ProgressArcFill
          thickness={Number(thickness)}
          rotation={rotateProgressBar(Number(progress) || 0)}
        />
      </ProgressArcWrapper>
    </Box>
  );
}

export default ProgressArc;
