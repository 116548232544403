import { useQuery } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";

export const getBirthdayCalendar = async (params) => {
  const { filter, period } = params;

  const res = await axios.middleware("/aluno.asmx/Aniversariantes");
  var data = res[0].data || [];

  const regex = new RegExp("(" + filter + ")", "i");
  const now = new Date();

  return data.filter(
    (item) =>
      regex.test(item?.nome) &&
      (period == "month" ||
        (period == "today" &&
          new Date(item?.dtnasc).getDate() == now.getDate()))
  );
};

export const useBirthdayCalendar = ({ config, params } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["birthdayCalendar", params],
    queryFn: () => getBirthdayCalendar(params),
  });
};
