import ProgressClock from "./ui/partials/ProgressClock";
import ProgressBar from "./ui/partials/ProgressBar";
import ProgressArc from "./ui/partials/ProgressArc";

const clock = "clock";
const bar = "bar";
const arc = "arc";

function ProgressTracker({ variant = "bar", thickness, progress, ...props }) {
  switch (variant) {
    case clock:
      return <ProgressClock progress={progress} {...props} />;
    case bar:
      return <ProgressBar progress={progress} {...props}/>;
    case arc:
      return <ProgressArc thickness={thickness} progress={progress} {...props}/>;
    default:
      return <ProgressBar progress={progress} {...props} />;
  }
}

export default ProgressTracker;