import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import RichTextInput from "../RichText/RichTextInput";

export default function RichTextField({
  fieldName,
  formMethods,
  labelText,
  helperText,
  RichTextInputProps,
}) {
  return (
    <Controller
      control={formMethods.control}
      name={fieldName}
      render={({ field, fieldState, ...rest }) => {
        return (
          <FormControl
            sx={{ mt: 1 }}
            helperText={helperText}
            error={fieldState.error}
          >
            {labelText && <InputLabel shrink>{labelText}</InputLabel>}
            <RichTextInput
              value={field.value}
              onChange={field.onChange}
              {...RichTextInputProps}
            />
            <FormHelperText>{fieldState.error || helperText}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}
