import { useMutation } from "@tanstack/react-query";
import { axios } from "../../../shared/lib/axios";
import _cookies from "../../../shared/utils/_cookies";
import { createNotification } from "../../../shared/store/notifications";

export const sendEmail = async ({ subject, message, recipient }) => {
  console.log(_cookies.getItem("auth"));
  const data = {
    alunoid: _cookies.getItem("auth").user?.alunoid,
    nome_aluno: _cookies.getItem("auth").user?.nome,
    destinatarioid: recipient.studentId,
    nome_destinatario: recipient.name,
    cat_destinatario: recipient.category || "A",
    assunto: subject,
    texto: message,
  };
  const body = new FormData();
  body.append("dados", `[${JSON.stringify(data)}]`);
  const res = await axios.middleware.post("/aluno.asmx/Enviar_Mensagem", body);
};

export const useSendEmail = ({ config } = {}) => {
  return useMutation({
    ...config,
    mutationFn: sendEmail,
    onSuccess: () => {
      createNotification({
        type: "success",
        message: "Email enviado com sucesso",
      });
    },
  });
};
