import { Typography, Box, styled } from "@mui/material";
import UserThemedPaper from "./UserThemedPaper";
import { EmojiEvents } from "@mui/icons-material";

const Section = styled("div")(({ theme }) => ({
  flexGrow: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& > .MuiTypography-root": {
    color: "grey",
  },
  ".emoji": { fontSize: "3rem" },
}));

export default function UserGameficationAwards() {
  return (
    <UserThemedPaper>
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        GAMIFICAÇÃO
      </Typography>
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
        }}
      >
        <Section>
          <Typography variant="h6">RANKING</Typography>
          <div className="flex">
            <p className="emoji">🏆</p>
            <span className="ml-2">
              <Typography sx={{ textAlign: "center" }} variant="h6">
                2º{" "}
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="caption">
                200 <br />
                pontos
              </Typography>
            </span>
          </div>
        </Section>
        <Section sx={{ flexGrow: "1" }}>
          <Typography variant="h6">CONQUISTAS</Typography>
          <div className="flex row">
            <p className="emoji">🥇</p>
            <p className="emoji">🥈</p>
            <p className="emoji">🥉</p>
          </div>
        </Section>
      </Box>
    </UserThemedPaper>
  );
}
