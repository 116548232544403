import { ChatBubble, Favorite } from "@mui/icons-material";
import ImageGallery from "../../../ui/components/ImageGallery";
import RichText from "../../../ui/components/RichText";
import UserThemedPaper from "./UserThemedPaper";
import UserTitle from "./UserTitle";
import { Box, IconButton } from "@mui/material";
export default function SocialMediaPostCompact() {
  return (
    <>
      <UserThemedPaper
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <UserTitle />
        <ImageGallery
          imgSrcs={[
            "https://fireworkweb.com.br/wp-content/uploads/2023/12/Teste-de-software-1920x1306-1.png",
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0KI8Ji-LEonZbtjfXPy1KnnbL_eEjgy2bBQ&s",
          ]}
        />
        <RichText value="Bom dia" />
      </UserThemedPaper>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <IconButton>
          <Favorite /> 52
        </IconButton>
        <IconButton>
          <ChatBubble />
          &nbsp;27
        </IconButton>
        <span></span>
      </Box>
    </>
  );
}
