import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";

let default_theme = createTheme({
  palette: {
    primary: {
      light: "#F79454",
      main: "#FF6600",
      dark: "#df5900",
      contrastText: "#FFFFFF",
    },
    secondary: { light: "#6c6c6c", main: "#4F4F4F", dark: "#393939" },
    menu: { background: "#363636", font: "#FFFFFF", icon: "#FFFFFF" },
    success: { main: "#27AE60" },
    danger: { main: "#EB5757" },
    text: { main: "#4F4F4F" },
    contraxtTreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontSize: 12,
    fontFamily: "Poppins, Roboto, sans-serif",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
        // sx: { fontSize: "10rem" },
        size: "small",
      },
    },
    MuiBadge: {
      styleOverrides: {
        // zIndex: 2,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: "primary",
      },
    },
  },
});

const reg = new RegExp(/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i);
const isColor = (string) => {
  return reg.test(string);
};
function Theme({ app: { params }, children, ...props }) {
  const buttonSize = useMediaQuery("min-width:600px") ? "blue" : "red";
  const theme = params
    ? createTheme(default_theme, {
        components: {
          // Name of the component
        },

        palette: {
          primary: {
            light: isColor(params?.NEW_primary_color_light)
              ? params?.NEW_primary_color_light
              : "#f79454",
            main: isColor(params?.NEW_primary_color_main)
              ? params?.NEW_primary_color_main
              : "#ff6600",
            dark: isColor(params?.NEW_primary_color_dark)
              ? params?.NEW_primary_color_dark
              : "#df5900",
          },
          secondary: {
            light: isColor(params?.NEW_secondary_color_light)
              ? params?.NEW_secondary_color_light
              : "#6c6c6c",
            main: isColor(params?.NEW_secondary_color_main)
              ? params?.NEW_secondary_color_main
              : "#4f4f4f",
            dark: isColor(params?.NEW_secondary_color_dark)
              ? params?.NEW_secondary_color_dark
              : "#393939",
          },
          menu: {
            background: params.NEW_menu_background_color || "#000",
            backgroundheader: params.NEW_primary_color_main || "#000",
            font: params.NEW_menu_font_color || "#000",
            icon: params.NEW_menu_icon_color,
          },
        },
      })
    : default_theme;

  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>{children}</ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  app: state.app,
});

export default connect(mapStateToProps)(Theme);
