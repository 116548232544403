export const getCourseStatus = (course, trackData) => {
  const {
    LIBERADO,
    ORDEM,
    acessoilimitado,
    matricula_ativa,
    matricula_conclusa,
  } = course;

  const { currentOrder, PROGRESSAO } = trackData;

  if (!!matricula_conclusa && matricula_conclusa >= (matricula_ativa || 0)) {
    return "completed";
  } else if (LIBERADO === "N") {
    return "disabled";
  } else if (LIBERADO === "S") {
    if (matricula_ativa) {
      return "active";
    } else if (PROGRESSAO === "MANUAL_LIVRE") {
      return "default";
    } else if (PROGRESSAO === "MANUAL_SEQUENCIAL" && currentOrder === ORDEM) {
      return "default";
    }
  } else {
    return "disabled";
  }
};
